<template>
    <div class="guides-area ptb-100">
        <div class="container">
            <div class="guides-content">
                <div class="number">1</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Early Planning</h3>
                            <span>Foundation of the Team</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>WealthCore LLC. was established in No. 1 Orchid Garden Street, Belmopan, Belize with a vision to revolutionize the digital landscape through innovative software solutions. Our founders, driven by a passion for technology and a commitment to excellence, laid the groundwork for what would become a dynamic and forward-thinking company.</p>
                            <img src="../../assets/img/create-account.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="guides-content">
                <div class="number">2</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>What We Do?</h3>
                            <span>Our Vision</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>From the beginning, our vision has been to harness the power of technology to create products that make a real difference in people's lives. We believe in pushing the boundaries of what's possible and continually strive to develop solutions that are not only innovative but also user-centric and impactful.</p>
                            <img src="../../assets/img/select-coin.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="guides-content">
                <div class="number">3</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Making a Revolution</h3>
                            <span>Journey to PayBuyGo</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>Our journey has been marked by continuous growth and development. Leveraging our expertise in blockchain technology and software development, we embarked on the creation of PayBuyGo. This platform is designed to simplify and enhance the cryptocurrency buying experience, offering users a fast, secure, and versatile way to engage with digital currencies.</p>
                            <img src="../../assets/img/payment-method.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="guides-content">
                <div class="number">4</div>
                <div class="row m-0">
                    <div class="col-lg-3 col-md-12 p-0">
                        <div class="box">
                            <h3>Advanced Technology</h3>
                            <span>PayBuyGo Today</span>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12 p-0">
                        <div class="content">
                            <p>Today, PayBuyGo stands as a testament to our dedication and hard work. It provides users worldwide with the ability to purchase cryptocurrencies easily, using a variety of payment methods, including credit cards and popular e-wallets. Our platform is available in over 190 countries, ensuring global accessibility and seamless transactions. At WealthCore LLC., we continue to innovate and expand, committed to delivering exceptional service and staying at the forefront of the digital currency revolution.</p>
                            <img src="../../assets/img/confirm-order.jpg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Guides'
}
</script>