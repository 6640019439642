<template>
    <div class="terms-conditions-area ptb-100">
        <div class="container">
            <div class="terms-conditions-content">

                <p>These terms and conditions govern your use of services provided by WealthCore LLC., the owner and operator of PayBuyGo. By accessing the PayBuyGo platform and using the services, you agree to these terms and conditions.</p>

                <h3>1. Definitions</h3>
                <p>“You / your” or “the Customer”: A natural person who has agreed to all terms and conditions of PayBuyGo and uses the services available on the platform.
“Your Account”: An account created by you on the platform, for which you are fully responsible.
“Your Wallet”: Your crypto wallet generated on the platform by PayBuyGo.
“Stored Cryptoassets”: Cryptoassets stored in your wallet. Ownership remains with you.
“Order”: Refers to the purchase or sale of cryptocurrency via the platform, or the deposit or withdrawal of cryptoassets to or from your wallet.
</p>

                <h3>2. Main Principles</h3>
                <p>WealthCore LLC. reserves the right to request additional information and documents from you if required by applicable law. Failure to provide requested information may limit your access to the platform.</p>
                <ul>
                    <li>The list of available cryptoassets may change without prior notice.</li>
                    <li>PayBuyGo does not provide services to legal entities.</li>
                    <li>PayBuyGo does not store fiat currency on your behalf.</li>
                    <li>PayBuyGo does not provide financial or investment advice.</li>
                </ul>
                
                <h3>3. Order Processing</h3>
                <p>Orders are processed through WealthCore LLC. PayBuyGo is not responsible for delays caused by issues beyond its control, such as telecommunications problems or actions of third parties.

An order is complete when the required amount of cryptocurrency or fiat is transferred to the indicated account. PayBuyGo may charge a processing fee, disclosed before you authorize the order.

If you provide inaccurate information, resulting in an incomplete order, refund fees will be borne by you. Funds cannot be returned after order completion.

Due to the volatile nature of cryptocurrency markets, price fluctuations may occur. If the price changes significantly before processing, PayBuyGo reserves the right to contact you about the new rate or reject the order.</p>
                
                <h3>4. Liability and Indemnification</h3>
                <p>PayBuyGo makes no guarantees regarding the time needed to complete processing, which depends on factors beyond its control. The services are provided "as is" without any warranties. PayBuyGo is not liable for losses or damages arising from use of the platform or services.

You agree to indemnify PayBuyGo from any claims arising from your use of the services or violation of these terms.</p>
                


                <h3>5. Acceptable Use</h3>
                <p>While using the services, you agree not to:</p>
                <ul>
                    <li>Engage in illegal, defamatory, or harassing behavior.</li>
                    <li>Impersonate someone else.</li>
                    <li>Use IP proxying to disguise your location.</li>
                    <li>PayBuyGo does not store fiat currency on your behalf.</li>
                    <li>Violate intellectual property or other rights.</li>
                    <li>Breach any applicable laws or regulations.</li>
                </ul>
                


                <h3>6. Dispute Resolution and Applicable Law</h3>
                <p>Any disputes will be resolved through mutual negotiations. If unresolved, the services are governed by and interpreted in accordance with Cypriot law.</p>
               

                <h3>7. Termination</h3>
                <p>PayBuyGo reserves the right to terminate your access to the services at any time without notice. You may terminate your account at any time.</p>
               
                 <h3>8. Eligibility</h3>
                <p>You must be of legal age, not previously suspended from the services, and not located in restricted jurisdictions. PayBuyGo reserves the right to reject orders and freeze assets if eligibility requirements are not met.</p>
                
                <h3>9. Restricted Jurisdictions</h3>
                <p>PayBuyGo does not provide services in certain restricted jurisdictions due to regulatory obligations. These jurisdictions include, but are not limited to, Afghanistan, Belarus, Central African Republic, Cuba, Democratic Republic of the Congo, Ethiopia, Iran, Iraq, Lebanon, Louisiana (state of the US), Libya, Mali, Myanmar, New York (state of the US), Nicaragua, North Korea, Russia, Somalia, South Sudan, Sudan, Syria, Venezuela, Yemen, and regions with unrecognized or disputed status.</p>
               
               <h3>10. Refund Policy</h3>
                <p>If PayBuyGo: 1- does not send the cryptocurrency to the Customer’s indicated address in accordance with the Order; or 2) does not send the fiat currency to the Customer’s account in accordance with the Order, PayBuyGo guarantees that the Customer has the right to a refund.

The Customer must follow the payment process provided on the platform. If the Customer sends funds to PayBuyGo and then requests a cancellation from a third-party financial institution before contacting PayBuyGo, the Customer is responsible for covering the cost of cancellation.

The right to a refund may arise only if the Customer has paid for the services in accordance with the Order, including all relevant fees. Any charges that arise upon processing the refund request shall be borne solely by the Customer. These charges are deducted from the final refund amount.

To apply for a refund, the Customer must complete a Refund Form and send it to support@paybis.com from the email address connected to the Customer’s account on the platform. The review of the refund request may start only after the Customer’s identity has been verified on the platform.

PayBuyGo shall process the refund request as soon as reasonably possible. Response times may vary depending on the stated reasons for the refund and the current workload. PayBuyGo shall notify the Customer of the outcome of the request. Submission of a Refund Form does not guarantee that the refund request will be approved.</p>
               
                <h3>11. Anti-Money Laundering Policy (AML)</h3>
                <p>Money laundering is the criminal process of converting illegally obtained money into legal investments. PayBuyGo actively participates in preventing such activities by:</p>
                <ul>
                    <li>Verifying the client’s identity and monitoring all transactions.</li>
                    <li>Tracing all suspicious operations.</li>
                    <li>Requesting additional verification or canceling transactions if illegal activity is suspected.</li>
                    <li>Acting in accordance with AML FATF guidelines without notifying the customer.</li>
                    <li>Regularly updating electronic systems and training staff to combat money laundering.</li>
                </ul>

<h3>12. Multiple Accounts Policy</h3>
                                <p>It is prohibited to have more than one account on the platform. If detected, all accounts will be temporarily disabled. The customer must choose one account to keep active while the others remain blocked permanently. Creating multiple accounts to circumvent payment method limits will result in additional verification requests. Failure to provide the requested information will lead to a refund of the payment to the original sender, with applicable fees deducted.</p>

<h3>13. Required and Supported Documents</h3>
                <p>Documents required during the verification process may include:</p>
                <ul>
                    <li>Identity Verification: Government-issued International Passport, Identity Card, or Driving License; Selfie with an Identity Document.</li>
                    <li>Address Verification: Utility bill or Bank Statement not older than 6 months.</li>
                    <li>Additional Verification Documents: Photo of Credit Card, Selfie with Credit Card, Photo with printed or handwritten declaration for high amounts.</li>
                     </ul>
                <p>All documents must be of good quality, readable, and without modifications.</p>


<h3>14. Refunds after Successful Cryptocurrency Purchase</h3>
                                <p>Refunds are not possible after the customer has received the purchased cryptocurrency. However, customers can sell the purchased cryptocurrency back to PayBuyGo based on the current market rate plus applicable fees.</p>


<h3>15. Chargebacks</h3>
                                <p>Customers should contact PayBuyGo’s support department to resolve any issues before initiating a chargeback request. PayBuyGo will investigate each chargeback request and communicate with the customer’s bank or financial institution. PayBuyGo reserves the right to suspend the customer’s account and lock funds during the chargeback investigation.</p>

<h3>16. Complaints Policy</h3>
                                <p>Complaints should be sent to support@paybuygo.com. PayBuyGo aims to respond within 15 days and records all complaints to improve services.</p>


<h3>17. Language Clause</h3>
                                <p>These terms and conditions may be translated for customer convenience. In case of disputes, the English version will prevail.</p>



                <p>Last updated: May 17, 2024 at 12:00 pm</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsConditions'
}
</script>