<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <h1>{{pageTitle}}</h1>
                <p>At PayBuyGo, operated by WealthCore LLC., we are committed to protecting your privacy and ensuring your rights are respected.</p>
            </div>
        </div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    props: ['pageTitle']
}
</script>