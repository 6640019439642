<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="contact-info">
                        <h3>Contact Address:</h3>
                        <p>Contact us with your details & ready to start with us. Get In Touch!</p>
                        <ul class="contact-list">
                            <li>
                                <div class="icon">
                                    <i class='bx bx-support'></i>
                                </div>
                                <p><a href="tel:+447464385589">+44 7464 385589</a></p>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-globe'></i>
                                </div>
                                <p><a href="mailto:support@paybuygo.com">support@paybuygo.com</a></p>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-map'></i>
                                </div>
                                <p>No. 1 Orchid Garden Street, Belmopan, Belize</p>

                            </li>
                        </ul>
                    
                    </div>
                </div>
                <div class="col-xl-8 col-lg-12 col-md-12">
                    <div class="maps">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.7001978630724!2d33.360312075071995!3d35.16409697275846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de1758f9dc1dd7%3A0x87a56015941fc78b!2zQXZsb25vcyAxLCBOaWNvc2lhIDEwNzUsIEvEsWJyxLFz!5e0!3m2!1str!2str!4v1717336807526!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                    </div>
                </div>
            </div>
          
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact'
}
</script>